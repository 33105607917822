import { useContext } from "react";
import { GetAllPrompts } from "./GetAllPrompts";

const engPrefix = "en";
const DefaultSpanishLanguage = "Spanish";
const DefaultEnglishLanguage = "English";
var userLang = navigator.language;

function ApplyDefaultLanguage() {
	var lang = sessionStorage.getItem("_lang");
	if (lang == null) {
		// if (userLang.startsWith(engPrefix)) {
		//   sessionStorage.setItem("_lang", DefaultEnglishLanguage);
		// } else {
		//   sessionStorage.setItem("_lang", DefaultSpanishLanguage);
		// }

		//Mary said she wanted the default language to be Spanish
		sessionStorage.setItem("_lang", DefaultSpanishLanguage);
	}
}

export function GetPrompts(searchItem) {
	ApplyDefaultLanguage();

	const lang = sessionStorage.getItem("_lang").toLowerCase();
	const prompts = JSON.parse(sessionStorage.getItem("_prompts"));

	if (prompts != null) {
		var matchingPrompt = prompts.filter(function (item) {
			return item.searchCode === searchItem;
		});
		if (matchingPrompt[0] == undefined) {
			return "Tag Not Found";
		}
		return matchingPrompt[0][`${lang}`];
	}
}

export async function InitiatePrompts(setPrompts) {
	ApplyDefaultLanguage();

	await GetAllPrompts().then((response) => {
		setPrompts(response);
		sessionStorage.setItem("_prompts", JSON.stringify(response));
	});
}
