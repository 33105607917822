export function Islanding() {
	if (
		window.location.href.toLowerCase().endsWith(".com/") ||
		window.location.href.toLowerCase().endsWith(".com") ||
		window.location.href.toLowerCase().endsWith("3000/") ||
		window.location.href.toLowerCase().endsWith("landing") ||
		window.location.href.toLowerCase().endsWith("landing_mobile") ||
		window.location.href.toLowerCase().endsWith("login_mobile") ||
		window.location.href.toLowerCase().endsWith("landing_alt") ||
		window.location.href.toLowerCase().endsWith("signup") ||
		window.location.href.toLowerCase().includes("pwdreset") ||
		window.location.href.toLowerCase().includes("privacypolicy") ||
		window.location.href.toLowerCase().includes("defaultwebpage.cgi")
	) {
		return true;
	}
	return false;
}
