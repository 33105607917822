export const SPINNER_STYLES = {
  position: "fixed",
  borderRadius: "30px",
  width: "160px",
  top: "50%",
  left: "40%",
  backgroundColor: "#ffa500",
  color: "#f7f7f5",
  zIndex: "1000",
};

export const SPINNER_STYLES_NOTEXT = {
  position: "fixed",
  borderRadius: "30px",
  width: "100px",
  height: "50px",
  top: "50%",
  left: "50%",
  backgroundColor: "#f7f7f5",
  color: "#f7f7f5",
  textAlign: "center",
  padding: "10px",
  zIndex: "1000",
};

export const SPINNER_STYLES_ORANGE = {
  position: "fixed",
  borderRadius: "30px",
  width: "100px",
  height: "50px",
  top: "50%",
  left: "50%",
  backgroundColor: "#ffa500",
  color: "#ffffff",
  textAlign: "center",
  padding: "10px",
  zIndex: "1000",
};
