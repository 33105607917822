import { css } from "aphrodite";
import React, { useContext, useEffect, useRef, useState } from "react";
import { animationStyles } from "../../Components/Animations/Styles";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { Context } from "../../Context/Contexts";
import "./HomePage.css";
import HomePageActivities from "./HomePageActivities";
import HomePageCalendar from "./HomePageCalendar";
import HomePageProducts from "./HomePageProducts";
import HomePageSidebar from "./HomePageSidebar";
import HomePageStudents from "./HomePageStudents";
import ErrorBoundary from "../../Components/ErrorBoundary/ErrorBoundary";
import { ErrorBoundary_HomeProducts } from "../../Components/ErrorBoundary/Settings/ErrorBoundary_HomeProducts";
import { ErrorBoundary_HomeStudents } from "../../Components/ErrorBoundary/Settings/ErrorBoundary_HomeStudents";
import { ErrorBoundary_HomeCalendar } from "../../Components/ErrorBoundary/Settings/ErrorBoundary_HomeCalendar";

export default function HomePage() {
	const [isMounted, setIsMounted] = useState(false);
	useEffect(() => {
		setIsMounted(true);
	}, []);

	const { homeNavBarIsOpen, setHomeNavBarIsOpen } = useContext(Context);
	setHomeNavBarIsOpen(true);

	return (
		<>
			<Sidebar />
			<div id="home-page-container" className="home-page-container">
				<div
					className={
						css(isMounted ? animationStyles.bounceInDown : null) +
						" home-page-sidebar sticky-side-container"
					}
				>
					<HomePageSidebar />
				</div>

				<div
					id="home-page-middle-container"
					className="home-page-middle-container"
				>
					<div
						className={
							css(isMounted ? animationStyles.bounceInDown : null) +
							" home-page-students"
						}
					>
						<ErrorBoundary
							errorMessage={ErrorBoundary_HomeStudents.errorMessage}
						>
							<HomePageStudents />
						</ErrorBoundary>
					</div>
					<div
						className={
							css(isMounted ? animationStyles.bounceInDown : null) +
							" home-page-activities"
						}
					>
						<HomePageActivities />
					</div>

					<div className="section-divider"></div>

					<div className="home-page-products">
						<ErrorBoundary
							errorMessage={ErrorBoundary_HomeProducts.errorMessage}
						>
							<HomePageProducts />
						</ErrorBoundary>
					</div>
				</div>

				<div className="home-page-calendar-sidebar">
					<ErrorBoundary errorMessage={ErrorBoundary_HomeCalendar.errorMessage}>
						<HomePageCalendar />
					</ErrorBoundary>
				</div>
			</div>
		</>
	);
}
