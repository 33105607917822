import axios from "../../axios";
export async function GetUserProfilePicture() {
	const url = "UserPicture/api/v1/GetUserProfilePictureFromAzure";
	try {
		const response = await axios.get(url, {
			params: {
				UserId: 0,
			},
		});

		return response.data.result.content;
	} catch (error) {}
}
