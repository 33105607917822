import React, { useEffect, useState } from "react";
import "./IsLoadingComponent.css";
import { MODAL_STYLES, OVERLAY_STYLES } from "./IsLoadingComponent_ModalStyles";
import { RESPONSIVE_MODAL_STYLES } from "../Modal_Styles/ResponsiveModalStyle";
import logo from "../../Assets/Logo.svg";
import { useContext } from "react";
import { Context } from "../../Context/Contexts";
export default function IsLoadingComponent({ open, close }) {
	const { showIsloading, setShowIsloading } = useContext(Context);
	const { showIsloadingMessage, setShowIsloadingMessage } = useContext(Context);

	const [isFading, setIsFading] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => {
			setIsFading(!isFading);
		}, 450);

		return () => clearTimeout(timer);
	}, [isFading]);
	useEffect(() => {
		if (showIsloading === false) {
			setShowIsloadingMessage("");
		}
	});

	if (!showIsloading) return;

	return (
		<>
			<div style={OVERLAY_STYLES}></div>

			<div
				id="is-loading-component"
				className="is-loading-component-container"
				style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES)}
			>
				<div
					className={`is-loading-component-circle ${
						isFading
							? "is-loading-component-fade-out"
							: "is-loading-component-fade-in"
					}`}
				>
					<img
						src={logo}
						className="is-loading-component-logo"
						alt="is-loading-component-logo"
					/>
				</div>
				<div className="is-loading-component-text">
					<span>{showIsloadingMessage}</span>
				</div>
			</div>
		</>
	);
}
