import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { Context } from "../../../Context/Contexts";
import { GetStyleToAdjustHeight_Alt } from "../../Helpers/GetStyleToAdjustHeight";
import { GetPrompts } from "../../Prompts/PromptsComponent";
import { GetProductsByUser } from "../GetProductsByUser";
import "./ProductHome.css";
import ProductHome_Card from "./ProductHome_Card";
import { Typography } from "@mui/material";

export default function ProductHome() {
	var loadCount = 0;
	const { setHomeNavBarIsOpen } = useContext(Context);

	const [productList, setProductList] = useState([]);
	const { setUpdateHeight } = useContext(Context);
	const navigate = useNavigate();
	const GetProductsLists = () => {
		GetProductsByUser().then(function (response) {
			setProductList(response.available);
			setUpdateHeight(true);
		});
	};

	useEffect(() => {
		if (loadCount > 0) {
			return;
		}
		InitialLoad();
	}, [loadCount]);

	const InitialLoad = () => {
		GetProductsLists();
		loadCount = loadCount + 1;
	};

	const boxRef = useRef();
	const { STYLES } = GetStyleToAdjustHeight_Alt(boxRef);

	return (
		<>
			<div className="product-home-container" ref={boxRef} style={STYLES}>
				<div className="">
					<Typography variant="baseTitle">
						{GetPrompts("Available products")}
					</Typography>
				</div>
				<PerfectScrollbar className="product-home-list">
					{productList ? (
						productList.map((product) => {
							return (
								<article key={`home-product-list-key-${product.productId}`}>
									<ProductHome_Card
										product={product}
										navigate={navigate}
										setHomeNavBarIsOpen={setHomeNavBarIsOpen}
									/>
								</article>
							);
						})
					) : (
						<></>
					)}
				</PerfectScrollbar>
			</div>
		</>
	);
}
