import axios from "../../axios";
export const UpdateChild = async (
  userId,
  planChildId,
  firstName,
  lastName,
  identification,
  dateOfBirth,
  yearId
) => {
  try {
    const url = `Child/api/v1/UpdateChild`

    const response = await axios.post(url, {
      UserId: userId,
      PlanChildId: planChildId,
      FirstName: firstName,
      LastName: lastName,
      Identification: identification,
      DateOfBirth: dateOfBirth,
      YearId: yearId,
    })
  } catch (error) {}
}
