import customAxios from "../../../axios";

export async function GetActivitiesForAutomatedPlanning({ yearId }) {
	const url = "Planner/api/v1/GetActivitiesForAutomatedPlanning";
	try {
		const response = await customAxios.get(url, {
			params: {
				UserId: 0,
				YearId: yearId,
			},
		});
		return response.data.result;
	} catch (error) {}
}
