import axios from "axios";
import axiosRetry from "axios-retry";
import Refresh from "../Pages/Login/RefreshToken/Refresh";

var backendUrl = "";

if (process.env.REACT_APP_ENV == "dev") {
	backendUrl = "https://localhost:7160";
} else {
	backendUrl = "https://oa-charlottemason-prod-backend.azurewebsites.net";
}

const customAxios = axios.create({
	baseURL: backendUrl,
});

axiosRetry(customAxios, {
	retries: 1,
	retryDelay: axiosRetry.exponentialDelay,
	shouldResetTimeout: true,
	retryCondition: (error) => {
		return error.response && error.response.status === 401;
	},
});

customAxios.interceptors.request.use(
	(req) => {
		const currentToken = JSON.parse(sessionStorage.getItem("_x"));
		if (currentToken) {
			req.headers = {
				Authorization: currentToken,
				CallerId: process.env.REACT_APP_CALLER_ID,
			};
		} else {
			req.headers = {
				CallerId: process.env.REACT_APP_CALLER_ID,
			};
		}

		return req;
	},
	(err) => {
		return Promise.reject(err);
	}
);

customAxios.interceptors.response.use(
	(res) => {
		if (res.status === 201) {
		}
		return res;
	},
	(err) => {
		var responseStatus = err.request.status;
		if (responseStatus === 401) {
			const currentToken = JSON.parse(sessionStorage.getItem("_x"));
			if (currentToken) {
				return Refresh().then(() => {
					return customAxios(err.config);
				});
			}
		}
		return Promise.reject(err);
	}
);
export default customAxios;
