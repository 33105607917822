export const MODAL_STYLES_CENTERED = {
  position: "fixed",
  width: "390px",
  height: "250px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#FFF",
  padding: "50px",
  zIndex: 1000,
};
