import React, { useContext, useState } from "react";
import ModalComponent from "../ModalComponent/ModalComponent";
import { MODAL_STYLES, OVERLAY_STYLES } from "./CouponComponent_ModalStyle";
import { Context } from "../../Context/Contexts";
import "./CouponComponent.css";
import { Typography } from "@mui/material";

export default function CouponComponent() {
	const { couponComponentIsOpen, setCouponComponentIsOpen } =
		useContext(Context);
	const { couponCode, setCouponCode } = useContext(Context);
	const { couponComponentCallBack } = useContext(Context);

	const CloseClickHandler = () => {
		setCouponCode("");
		setCouponComponentIsOpen(false);
	};

	const applyCouponHandler = () => {
		setCouponComponentIsOpen(false);
	};

	if (!couponComponentIsOpen) return null;
	return (
		<>
			<ModalComponent
				isOpen={couponComponentIsOpen}
				onClose={CloseClickHandler}
				overlayStyle={OVERLAY_STYLES}
				modalStyle={MODAL_STYLES}
				showBackgroundImage={false}
			>
				<div className="coupon-component-container">
					<div
						className="coupon-component-close-btn"
						onClick={CloseClickHandler}
					>
						X
					</div>
					<div>CUPÓN</div>
					<div className="coupon-input-section">
						<Typography variant="baseSubTitle">
							<input
								type="text"
								value={couponCode}
								onChange={(e) => setCouponCode(e.target.value)}
								placeholder="Ingresa el codigo de un cupón"
								className="coupon-input"
							/>
						</Typography>
						<button
							onClick={() => {
								applyCouponHandler();
							}}
							className="apply-coupon-btn hover-brighten"
						>
							Aplicar
						</button>
					</div>
				</div>
			</ModalComponent>
		</>
	);
}
