import axios from "../../axios";
export const SaveUserProfilePicture = async ({ file }) => {
	try {
		const url = `UserPicture/api/v1/SaveUserProfilePictureInAzure`;

		const response = await axios.post(url, file);

		return response.data.result.pictureData;
	} catch (error) {}
};
