import { GetYearPlanDropdowns } from "../Planner/YearPlan/Dropdowns/GetYearPlanDropdowns";

export async function YearActivityDropdowns(
	yearPlanDropdowns,
	setYearPlanDropdowns
) {
	try {
		if (yearPlanDropdowns.years.length > 0) {
			return yearPlanDropdowns;
		}
	} catch (error) {}

	GetYearPlanDropdowns().then(function (result) {
		setYearPlanDropdowns(result);
		return result;
	});
}
