import React, { useEffect, useState } from "react";
import * as IoIcons from "react-icons/io";
import { RESPONSIVE_MODAL_STYLES } from "../Modal_Styles/ResponsiveModalStyle";
import "./CountyList.css";
import { MODAL_STYLES, OVERLAY_STYLES } from "./CountyList_ModalStyle";
import { GetAllCountries } from "./GetAllCountries";

export default function CountyList({
  preselectedItem,
  isOpen,
  close,
  setCountryId,
  setCountryToDisplay,
}) {
  const [currentSelectionArr, setCurrentSelectionArr] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState(0);
  const [selectedItemDescription, setSelectedItemDescription] = useState(0);
  const [countryList, setCountryList] = useState([]);
  const [listForFiltering, setListForFiltering] = useState([]);
  const [filterby, setFilterby] = useState("");

  const GetCountries = () => {
    GetAllCountries().then((response) => {
      setCountryList(response);
      setListForFiltering(response);
    });
  };

  const FeedCountryToDisplay = (itemId) => {
    countryList.filter((val) => {
      if (val.countryId == itemId) {
        setCountryToDisplay(val.description);
      }
    });
  };
  const Filter = ({ value }) => {
    const handleChange = (event) => {
      setFilterby(event.target.value)
    }

    return (
      <div className="year-plan-activities-selector-filter-container">
        <input
          className="year-plan-activities-selector-filter base-parafraph"
          autoFocus
          type="text"
          onChange={handleChange}
          value={filterby}
          placeholder="Filtrar..."
        />
      </div>
    )
  }

  useEffect(() => {
    if (isOpen) {
      ApplyFilter()
    }
  }, [filterby])

  const ApplyFilter = () => {
    var filteredJsonArray = listForFiltering.filter((item) => {
      return item.description.toLowerCase().includes(filterby.toLowerCase())
    })
    setCountryList(filteredJsonArray)
  }

  const SelectItem = (itemId) => {
    AddSelectedItem(itemId)
    setCountryId(itemId)
  }

  const AddSelectedItem = (itemId) => {
    var currselection = currentSelectionArr
    var itemFound = false
    currselection.filter((val) => {
      if (val == itemId) {
        itemFound = true
        var index = currselection.indexOf(itemId)
        currselection.splice(index, 1)
      }
    })

    if (!itemFound) {
      currselection = [] //No multiselect
      currselection.push(itemId)
    }

    if (currselection.length > 0) {
      setSelectedItemId(itemId)
      FeedCountryToDisplay(itemId)
    } else {
      setSelectedItemId(0)
    }
    setCurrentSelectionArr(currselection)
  }

  useEffect(() => {
    if (isOpen) {
      GetCountries()
      SelectItem(preselectedItem)
    } else {
      setFilterby()
      setCurrentSelectionArr([])
    }
  }, [isOpen])

  return (
    <>
      {isOpen ? (
        <div>
          <div
            style={OVERLAY_STYLES}
            onClick={() => {
              close()
            }}
          ></div>

          <div
            style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES)}
            className="country-list-container"
          >
            <div>
              <Filter />
            </div>
            <div>
              {countryList.map((country) => {
                var index = currentSelectionArr.indexOf(country.countryId)
                var showAsSelected = false
                if (index == -1) {
                  showAsSelected = false
                } else {
                  showAsSelected = true
                }
                return (
                  <>
                    <div className="country-list-card">
                      <div
                        className="country-list-card-description base-parafraph"
                        onClick={() => {
                          SelectItem(country.countryId)
                          setTimeout(() => close(), 300)
                        }}
                      >
                        {country.description}{" "}
                        {showAsSelected ? <IoIcons.IoMdCheckmark /> : ""}
                      </div>
                    </div>
                  </>
                )
              })}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  )
}
