import moment from "moment";
import React, { useState } from "react";
import Calendar from "react-calendar";
import { RESPONSIVE_MODAL_STYLES } from "../Modal_Styles/ResponsiveModalStyle";
import "./DatePickerPopUp.css";
import { MODAL_STYLES, OVERLAY_STYLES } from "./DatePickerPopUp_ModalStyle";

export default function DatePickerPopUp({
	open,
	close,
	defaultValue,
	onChange,
	defaultView = "month",
}) {
	const [defaultDate, setDefaultDate] = useState(new Date());
	const [testDate, setTestDate] = useState(new Date());

	const OnChangeHandler = (e) => {
		var date = moment(new Date(e)).format("MM/DD/yyyy");
		onChange(date);
		close();
	};

	const CloseClickHandler = () => {
		close();
	};

	const handleTestDateChange = (e) => {
		var date = moment(new Date(e.target.value)).format("MM/DD/yyyy");
		setTestDate(e.target.value);
	};
	const handleSendClick = () => {
		onChange(testDate);
	};

	if (!open) return null;
	return (
		<>
			<article>
				<div
					id="date-time-picker-pop-up-close-btn"
					style={OVERLAY_STYLES}
					onClick={CloseClickHandler}
				></div>

				<div
					className="date-time-picker-pop-up-container"
					style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES)}
				>
					<Calendar
						defaultView={defaultView}
						onChange={(e) => OnChangeHandler(e)}
						value={defaultValue ?? defaultDate}
					/>

					{/* Hidden input for testing purposes */}
					<div id="calendar-hidden-div" style={{ display: "none" }}>
						<input
							type="text"
							value={testDate}
							onChange={(e) => handleTestDateChange(e)}
							id="hidden-test-date-input"
						/>
						<button
							id="calendar-hidden-send-btn"
							onClick={() => {
								handleSendClick();
							}}
						></button>
					</div>
				</div>
			</article>
		</>
	);
}
