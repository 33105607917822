import { createTheme } from "@mui/material/styles";

const theme = createTheme({
	typography: {
		fontFamily:
			"Poppins, Arial, sans-serif, Newsreader , Alegreya, Lora, Open Sans",

		h1: {
			fontSize: "clamp(1.5rem, 2.5vw, 2.5rem)", // Minimum: 1.5rem, Scales with viewport, Maximum: 2.5rem
			fontFamily: "Alegreya",
			fontWeight: "bold",
		},
		h2: {
			fontSize: "clamp(1.25rem, 2vw, 2rem)", // Minimum: 1.25rem, Maximum: 2rem
			fontFamily: "Alegreya",
			fontWeight: "bold",
		},
		h3: {
			fontSize: "clamp(1.125rem, 1.8vw, 1.75rem)", // Minimum: 1.125rem, Maximum: 1.75rem
			fontFamily: "Alegreya",
			fontWeight: "bold",
		},
		h4: {
			fontSize: "clamp(1rem, 1.6vw, 1.5rem)", // Minimum: 1rem, Maximum: 1.5rem
			fontFamily: "Alegreya",
			fontWeight: "bold",
		},
		baseTitle: {
			fontSize: "clamp(0.875rem, 1.4vw, 1.25rem)", // Minimum: 0.875rem, Maximum: 1.25rem
			fontFamily: "Alegreya",
			fontWeight: "bold",
		},
		h6: {
			fontSize: "clamp(0.75rem, 1.2vw, 1rem)", // Minimum: 0.75rem, Maximum: 1rem
			fontFamily: "Alegreya",
			fontWeight: "bold",
		},

		// Body text that scales fluidly
		body1: {
			fontSize: "clamp(0.875rem, 1.5vw, 1.125rem)", // Minimum: 0.875rem, Maximum: 1.125rem
			fontFamily: "Open Sans",
		},
		body2: {
			fontSize: "clamp(0.75rem, 1.2vw, 1rem)", // Minimum: 0.75rem, Maximum: 1rem
			fontFamily: "Open Sans",
		},
		bodyParagraph: {
			fontSize: "clamp(0.75rem, 1.2vw, 1rem)", // Minimum: 0.75rem, Maximum: 1rem
			fontFamily: "Alegreya",
		},

		// Buttons with fluid font sizes
		button: {
			fontSize: "clamp(0.75rem, 1.1vw, 1rem)", // Minimum: 0.75rem, Maximum: 1rem
			fontFamily: "Alegreya",
		},

		// Caption text (small text) with fluid scaling
		caption: {
			fontSize: "clamp(0.625rem, 0.9vw, 0.875rem)", // Minimum: 0.625rem, Maximum: 0.875rem
		},
		// Table row content for table headers (th) and data (td)
		tableHeader: {
			fontSize: "clamp(0.75rem, 1.2vw, 1rem)", // Min: 0.875rem, Max: 1.25rem
			fontWeight: 700, // Bold text for table headers
			fontFamily: "Lora",
		},
		tableRow: {
			fontSize: "clamp(0.75rem, 1.1vw, 1rem)", // Min: 0.75rem, Max: 1rem
			fontFamily: "Open Sans",
		},
	},
});

export default theme;
