import React, { useContext } from "react";

import "./MembershipNeedUpgrade.css";
import {
	MODAL_STYLES,
	OVERLAY_STYLES,
} from "./MembershipNeedUpgrade_ModalStyles";
import { Context } from "../../../Context/Contexts";
import { RESPONSIVE_MODAL_STYLES } from "../../Modal_Styles/ResponsiveModalStyle";
import freeMembershipLogo from "../../../Assets/MembershipIcons/Free_Membership_Logo.jpg";
import { useNavigate } from "react-router-dom";
import { GetPrompts } from "../../Prompts/PromptsComponent";
export default function MembershipNeedUpgrade() {
	const { showMembershipNeedUpgrade, setShowMembershipNeedUpgrade } =
		useContext(Context);
	const navigate = useNavigate();

	const CloseClickHandler = () => {
		setShowMembershipNeedUpgrade(false);
	};

	if (!showMembershipNeedUpgrade) return;

	return (
		<>
			<div style={OVERLAY_STYLES} onClick={CloseClickHandler}></div>

			<div
				className="membership-need-upgrade-container"
				style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES)}
			>
				<div className="membership-need-upgrade-icon">
					<img src={freeMembershipLogo} width={"100px"} />
				</div>
				<div className="membership-need-upgrade-text base-title-normal-height">
					{GetPrompts("Upgrade needed text")}
				</div>
				<div className="membership-need-upgrade-btns-container">
					<span
						className="membership-need-upgrade-btns-close hover-brighten"
						onClick={CloseClickHandler}
					>
						{GetPrompts("Close")}
					</span>
					<span
						className="membership-need-upgrade-btns-ckeck-options hover-brighten"
						onClick={() => {
							CloseClickHandler();
							navigate("/membership");
						}}
					>
						{GetPrompts("View Options")}
					</span>
				</div>
			</div>
		</>
	);
}
