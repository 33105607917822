import customAxios from "../../axios";

export const RevertPlannerAction = async () => {
	try {
		const url = `Planner/api/v1/RevertAction`;

		const response = await customAxios.post(url, {
			UserId: 0,
		});
		return response.data;
	} catch (error) {}
};
