import React, { useContext } from "react";
import * as IoIcons from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { LogOutLink_Home } from "../../../../Components/LogOutLink/LogOutLink";
import { GetPrompts } from "../../../../Components/Prompts/PromptsComponent";
import { Context } from "../../../../Context/Contexts";

import { UserProfileIcon_Home } from "../../../../Pages/UserProfile/UserProfile";

export function YearPlannerSidebar() {
	const { selectedChildGlobal } = useContext(Context);
	const { setHomeNavBarIsOpen } = useContext(Context);
	const { setShowAutomatedPlanning } = useContext(Context);
	const navigate = useNavigate();
	const GotoModule = (module) => {
		navigate(module);
	};
	return (
		<>
			<div className="home-page-sidebar-container-year-plan">
				<div className="home-page-sidebar-upper">
					<div className="home-page-sidebar-item">
						<div className="home-page-sidebar-item-icon">
							<IoIcons.IoMdCalendar />
						</div>
						<div
							id="year-planner-sidebar-weekly-calendar"
							className="home-page-sidebar-item-text sidebar-menu-item"
							onClick={() => {
								GotoModule("/WeeklyScheduleOrdered");
							}}
						>
							{GetPrompts("Sidebar Schedule")}
						</div>
					</div>

					<div className="home-page-sidebar-item">
						<div className="home-page-sidebar-item-icon">
							<IoIcons.IoMdCalendar />
						</div>
						<div
							className="home-page-sidebar-item-text sidebar-menu-item"
							onClick={() => {
								GotoModule("/WeeklySchedule");
							}}
						>
							{GetPrompts("Sidebar Schedule With Time")}
						</div>
					</div>

					<div className="home-page-sidebar-item">
						<div className="home-page-sidebar-item-icon">
							<IoIcons.IoMdList />
						</div>
						<div
							className="home-page-sidebar-item-text sidebar-menu-item"
							onClick={() => {
								GotoModule("/PlanPerDayCustomReport");
							}}
						>
							{GetPrompts("Sidebar Activities Report")}
						</div>
					</div>
					{selectedChildGlobal != undefined ? (
						<div className="home-page-sidebar-item">
							<div className="home-page-sidebar-item-icon">
								<IoIcons.IoIosAnalytics />
							</div>
							<div
								className="home-page-sidebar-item-text sidebar-menu-item"
								onClick={() => {
									setShowAutomatedPlanning(true);
								}}
							>
								Planeacion automatica
							</div>
						</div>
					) : (
						<></>
					)}
				</div>

				<div className="home-page-sidebar-lower-year-plan">
					<UserProfileIcon_Home />
					<LogOutLink_Home />
				</div>
			</div>
		</>
	);
}
