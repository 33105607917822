import { useEffect, useReducer, useState } from "react";
import { defaultState } from "../Components/Reducer/DefaultState";
import reducer from "../Components/Reducer/Reducer";
import { NewUser } from "../Pages/SignUp/NewUser";
import { InitiatePrompts } from "../Components/Prompts/PromptsComponent";

export default function ContextProviderValues() {
	const [state, dispatch] = useReducer(reducer, defaultState);

	const [isloading, setIsloading] = useState(false);
	const [showIsloading, setShowIsloading] = useState(false);
	const [showIsloadingMessage, setShowIsloadingMessage] = useState("");

	const [showDonecomponent, setShowDonecomponent] = useState(false);

	const [showNavbarItems, setShowNavbarItems] = useState(true);
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);
	const [showSidebarToggle, setShowSidebarToggle] = useState(false);
	const [showFooter, setShowFooter] = useState(true);
	const [cartItemCounter, setCartItemCounter] = useState(0);
	const [refreshYearPlanner, setRefreshYearPlanner] = useState(false);
	const [logInIsOpen, setLogInIsOpen] = useState(false);
	const [showForgotPwd, setShowForgotPwd] = useState(false);
	const [showToolTip, setShowToolTip] = useState(false);
	const [userProfileIsOpen, setUserProfileIsOpen] = useState(false);
	const [contactUsIsOpen, setContactUsIsOpen] = useState(false);
	const [pageLanguage, setPageLanguage] = useState("");
	const [prompts, setPrompts] = useState([]);
	const [homeNavBarIsOpen, setHomeNavBarIsOpen] = useState(true);
	const [updateProductsHeight, setUpdateProductsHeight] = useState(false);
	const [newUser, setNewUser] = useState(NewUser);
	const [updatePictureProfile, setupdatePictureProfile] = useState(false);
	const [updateHeight, setUpdateHeight] = useState(false);
	const [refreshWeeklySchedule, setRefreshWeeklySchedule] = useState(false);
	const [showNewPlannedActivity, setShowNewPlannedActivity] = useState(false);
	const [showUsersInTheWorld, setShowUsersInTheWorld] = useState(false);
	const [updateMentorshipAvailableSlots, setUpdateMentorshipAvailableSlots] =
		useState(false);
	const [
		updateMentorshipAppointmentsList,
		setUpdateMentorshipAppointmentsList,
	] = useState(false);
	const [showMembershipNeedUpgrade, setShowMembershipNeedUpgrade] =
		useState(false);
	const [exitApp, setExitApp] = useState(false);
	const [showChildrenSelectorPopUp, setShowChildrenSelectorPopUp] =
		useState(false);
	const [selectedChildGlobal, setSelectedChildGlobal] = useState();
	const [showSelectAllChildrenOption, setShowSelectAllChildrenOption] =
		useState(true);
	const [updateNavbarUserData, setUpdateNavbarUserData] = useState(false);
	const [yearPlanDropdowns, setYearPlanDropdowns] = useState([]);
	const [updateChildrenList, setUpdateChildrenList] = useState(false);
	const [userIsMember, setUserIsMember] = useState(false);
	const [calendarSettingsIsOpen, setCalendarSettingsIsOpen] = useState(false);
	const [hideWeekendInCalendar, setHideWeekendInCalendar] = useState(false);
	const [activitiesInEditionList, setActivitiesInEditionList] = useState([]);
	const [showAutomatedPlanning, setShowAutomatedPlanning] = useState(false);
	const [couponComponentIsOpen, setCouponComponentIsOpen] = useState(false);
	const [couponComponentCallBack, setCouponComponentCallBack] = useState();
	const [couponCode, setCouponCode] = useState("");

	useEffect(() => {
		InitiatePrompts(setPrompts);
	}, [0]);

	return {
		state,
		dispatch,
		showNavbarItems,
		setShowNavbarItems,
		isloading,
		setIsloading,
		showDonecomponent,
		setShowDonecomponent,
		showIsloading,
		setShowIsloading,
		showIsloadingMessage,
		setShowIsloadingMessage,
		isSidebarOpen,
		setIsSidebarOpen,
		showSidebarToggle,
		setShowSidebarToggle,
		showFooter,
		setShowFooter,
		cartItemCounter,
		setCartItemCounter,
		refreshYearPlanner,
		setRefreshYearPlanner,
		loinIsOpen: logInIsOpen,
		setLoinIsOpen: setLogInIsOpen,
		showForgotPwd,
		setShowForgotPwd,
		showToolTip,
		setShowToolTip,
		userProfileIsOpen,
		setUserProfileIsOpen,
		contactUsIsOpen,
		setContactUsIsOpen,
		pageLanguage,
		setPageLanguage,
		prompts,
		setPrompts,
		homeNavBarIsOpen,
		setHomeNavBarIsOpen,
		updateProductsHeight,
		setUpdateProductsHeight,
		newUser,
		setNewUser,
		updatePictureProfile,
		setupdatePictureProfile,
		updateHeight,
		setUpdateHeight,
		refreshWeeklySchedule,
		setRefreshWeeklySchedule,
		showNewPlannedActivity,
		setShowNewPlannedActivity,
		showUsersInTheWorld,
		setShowUsersInTheWorld,
		updateMentorshipAvailableSlots,
		setUpdateMentorshipAvailableSlots,
		updateMentorshipAppointmentsList,
		setUpdateMentorshipAppointmentsList,
		showMembershipNeedUpgrade,
		setShowMembershipNeedUpgrade,
		exitApp,
		setExitApp,
		showChildrenSelectorPopUp,
		setShowChildrenSelectorPopUp,
		selectedChildGlobal,
		setSelectedChildGlobal,
		showSelectAllChildrenOption,
		setShowSelectAllChildrenOption,
		updateNavbarUserData,
		setUpdateNavbarUserData,
		yearPlanDropdowns,
		setYearPlanDropdowns,
		updateChildrenList,
		setUpdateChildrenList,
		userIsMember,
		setUserIsMember,
		calendarSettingsIsOpen,
		setCalendarSettingsIsOpen,
		hideWeekendInCalendar,
		setHideWeekendInCalendar,
		activitiesInEditionList,
		setActivitiesInEditionList,
		showAutomatedPlanning,
		setShowAutomatedPlanning,
		couponComponentIsOpen,
		setCouponComponentIsOpen,
		couponCode,
		setCouponCode,
		couponComponentCallBack,
		setCouponComponentCallBack,
	};
}
