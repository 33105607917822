import axios from "../../axios";
export const SendCustomerSupportMessage = async (message) => {
  try {
    const url = `CustomerSupport/api/v1/SendCustomerSupportMessage`

    const response = await axios.put(url, {
      UserId: 0,
      Message: message,
    });

    return response.data;
  } catch (error) {}
};
