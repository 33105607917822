import axios from "../../axios";
export const EditUserProfile = async (
  email,
  firstName,
  lastName,
  preferredName,
  language,
  countryId
) => {
  try {
    const url = `User/api/v1/EditUserProfile`

    const response = await axios.put(url, {
      UserId: 0,
      Username: email,
      FirstName: firstName,
      LastName: lastName,
      PreferredName: preferredName,
      Language: language,
      CountryId: countryId,
    });

    return response.data;
  } catch (error) {}
};
