import React, { useContext } from "react";
import * as IoIcons from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { GetPrompts } from "../../Components/Prompts/PromptsComponent";
import { Context } from "../../Context/Contexts";
import "./HomePageActivities.css";
import { Typography } from "@mui/material";
export default function HomePageActivities() {
	const navigate = useNavigate();
	const { setHomeNavBarIsOpen } = useContext(Context);

	return (
		<>
			<div className="home-page-activities-container">
				<div className="">
					<Typography variant="baseTitle">{GetPrompts("My Plan")}</Typography>
				</div>
				<div className="home-page-activities-card-container">
					<div className="home-page-activities-card-text ">
						<Typography variant="body2">
							{GetPrompts("Home plan link description")}
						</Typography>
					</div>
					<div className="home-page-activities-card-btn-container">
						<div
							className="home-page-activities-card-btn hover-brighten"
							onClick={() => {
								navigate("/planner-Year");
							}}
						>
							<Typography variant="body2">
								{GetPrompts("View Planner")}
								<IoIcons.IoMdArrowDropright />
							</Typography>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
