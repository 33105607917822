import React from "react";
import * as IoIcons from "react-icons/io";
import { GetPrompts } from "../Prompts/PromptsComponent";
import "./BlogLink.css";
import { GotoBlog } from "./GotoBlog";
export default function BlogLink({ isOverallMenu = false }) {
	const ClickHandler = () => {
		window.open(
			"https://alivingeducationenespanol.com/2019/07/18/principios-de-la-filosofia-de-educacion-de-charlotte-mason/",
			"_blank"
		);
	};

	return (
		<>
			<div
				className="blog-link-icon"
				onClick={() => {
					ClickHandler();
				}}
			>
				<IoIcons.IoLogoWordpress />
				<span className={`sidebar-item-text overall-sidebar-menu-item`}>
					{GetPrompts("Home Sidebar Blog")}
				</span>
			</div>
		</>
	);
}

export function BlogLink_Home() {
	return (
		<>
			<div
				className="blog-link-home-container"
				onClick={() => {
					GotoBlog();
				}}
			>
				<div className="blog-link-home-icon">
					<IoIcons.IoLogoWordpress />
				</div>
				<div className="blog-link-home-text sidebar-menu-item">
					{GetPrompts("Home Sidebar Blog")}
				</div>
			</div>
		</>
	);
}
