export function NewChildIsValidRequest({
  childFirstName,
  childLastName,
  childId,
  childDateOfBirth,
  yearId,
}) {
  if (
    childFirstName == '' ||
    childLastName == '' ||
    childId == '' ||
    childDateOfBirth == '' ||
    yearId == ''
  ) {
    return false
  }
  return true
}
