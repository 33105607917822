import axios from "../../axios";
export async function GetChildren({ currentPage = 1, itemsPerPage = 10 }) {
  const url = "Child/api/v1/GetAllChildPaged"
  try {
    const response = await axios.get(url, {
      params: {
        CurrentPage: currentPage,
        ItemsPerPage: itemsPerPage,
      },
    });
    return response.data;
  } catch (error) {}
}
