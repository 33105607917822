import { faHashtag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../../../Context/Contexts'
import {
  MODAL_STYLES_BACKGROUND,
  RESPONSIVE_MODAL_STYLES,
} from '../../../Modal_Styles/ResponsiveModalStyle'
import { GetPrompts } from '../../../Prompts/PromptsComponent'
import './YearSelector.css'
import YearSelectorCard from './YearSelectorCard'
import './YearSelectorGrid.css'
import { MODAL_STYLES, OVERLAY_STYLES } from "./YearSelector_ModalStyle";
import { YearActivityDropdowns } from "../../../GetDropdowns/YearActivityDropdowns";

export default function YearSelector({
	isopen,
	close,
	selectedYear,
	setSelectedYear,
}) {
	var loadCount = 0;
	const [yearsList, setYearsList] = useState([]);
	const { yearPlanDropdowns, setYearPlanDropdowns } = useContext(Context);

	const CloseClickHandler = () => {
		close();
	};

	const GetDropdowns = () => {
		YearActivityDropdowns(yearPlanDropdowns, setYearPlanDropdowns).then(
			(result) => {
				setYearsList(result.years);
			}
		);
	};

	useEffect(() => {
		if (loadCount > 0) {
			return;
		}
		InitialLoad();
	}, [loadCount]);

	useEffect(() => {
		if (isopen) {
			GetDropdowns();
		}
	}, [isopen]);

	const InitialLoad = () => {
		GetDropdowns();
		loadCount = loadCount + 1;
	};

	if (!isopen) return;

	return (
		<>
			<div style={OVERLAY_STYLES} onClick={CloseClickHandler}></div>

			<div
				className="year-selector-modal-overall-container"
				style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES)}
			>
				<div
					className="year-selector-modal-close-btn"
					onClick={CloseClickHandler}
				>
					X
				</div>

				<div className="year-selector-modal-title">{GetPrompts("Years")}</div>

				<div className="year-selector-modal-container">
					{yearsList.map((year) => {
						return (
							<article key={`year-selector-modal-key${year.yearId}`}>
								<div
									id={`year-selector-modal-item-id-${year.yearId}`}
									className={`year-selector-modal-item-container ${
										selectedYear.yearId == year.yearId ? "selected-year" : ""
									}`}
									onClick={() => {
										setSelectedYear(year);
										CloseClickHandler();
									}}
								>
									<div className={`year-selector-modal-item-text`}>
										{year.yearId}
									</div>
								</div>
							</article>
						);
					})}
				</div>
			</div>
		</>
	);
}
