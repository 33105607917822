import { Avatar, Typography } from "@mui/material";
import { green } from "@mui/material/colors";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { GetPrompts } from "../../Prompts/PromptsComponent";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import ChildCard_Edit from "../ChildCard_Edit";
import { EmptyChild } from "../EmptyChild";
import { GetChildren } from "../GetChildren";
import "./ChildrenHome.css";
import PlannedActivitiesPerChildPerYear from "../../Planner/PlannedActivitiesPerChildPerYear/PlannedActivitiesPerChildPerYear";
import { Context } from "../../../Context/Contexts";

export default function ChildrenHome({
	selectionEnabled,
	selectedItem,
	setSelectedItem,
}) {
	var loadCount = 0;
	const { updateChildrenList, setUpdateChildrenList } = useContext(Context);
	const [isOpen, setIsOpen] = useState(false);
	const [childrenList, setChildrenList] = useState([]);
	const [childInEdition, setChildInEdition] = useState("");
	const [showActivitiesPerYear, setShowActivitiesPerYear] = useState(false);

	const FeedChildrenList = () => {
		GetChildren({ currentPage: 0, itemsPerPage: 100 }).then(function (
			response
		) {
			setChildrenList(response.result);
		});
	};

	useEffect(() => {
		if (loadCount > 0) {
			return;
		}
		InitialLoad();
	}, [loadCount]);

	useEffect(() => {
		if (updateChildrenList) {
			setUpdateChildrenList(false);
			FeedChildrenList();
		}
	}, [updateChildrenList]);

	const InitialLoad = () => {
		FeedChildrenList();
		loadCount = loadCount + 1;
	};

	return (
		<>
			<PerfectScrollbar className="children-home-container">
				<div>
					<Typography variant="baseTitle">
						{GetPrompts("My Students Text")}
					</Typography>
				</div>
				<>
					<div>
						<table className="children-home-table">
							<tr
								className="children-home-table-header"
								key={`children-home-card-container-header-key`}
							>
								<th className="children-home-table-header-name">
									<Typography variant="tableHeader">
										{GetPrompts("Name text")}/{GetPrompts("Last Name text")}
									</Typography>
								</th>
								<th>
									<Typography variant="tableHeader">
										{GetPrompts("Date Of Birth Text")}
									</Typography>
								</th>
								<th>
									{" "}
									<Typography variant="tableHeader">
										{GetPrompts("Current year")}
									</Typography>
								</th>
								<th></th>
								<th></th>
							</tr>

							{childrenList.map((child) => {
								var selectedchildId =
									selectedItem == undefined ? 0 : selectedItem.planChildId;
								return (
									<tr
										className={`children-home-card-container ${
											selectionEnabled ? "selection-enabled" : ""
										} ${
											child.planChildId == selectedchildId
												? "selected-child"
												: ""
										}`}
										key={`children-home-card-container-key-home${child.planChildId}`}
										onClick={() => {
											setSelectedItem(child);
										}}
									>
										<td className="children-home-card-name table-row-item">
											<div className="children-home-card-name-avatar">
												<Avatar sx={{ bgcolor: green[200] }}>
													<span className="children-home-card-name-avatar-text">
														{child.firstName.charAt(0)}
													</span>
												</Avatar>
											</div>
											<div className="children-home-card-name-text table-row-item">
												<Typography variant="tableRow">
													{child.firstName} {child.lastName}
												</Typography>
											</div>
										</td>
										<td className="children-home-card-dob table-row-item">
											<Typography variant="tableRow">
												{moment(child.dateOfBirth).format("DD/MM/YYYY")}
											</Typography>
										</td>
										<td className="children-home-card-year-text table-row-item">
											<Typography variant="tableRow">
												{child.planYear.yearId}
											</Typography>
										</td>
									</tr>
								);
							})}

							<tr className={"children-home-card-container"}>
								<td className="children-home-card-name">
									<div className="children-home-card-name-avatar"></div>
									<div className="children-home-card-name-text"></div>
								</td>
								<td className="children-home-card-dob">...</td>
								<td>
									<span
										className="children-home-card-edit table-row-item"
										onClick={() => {
											const emptyChild = EmptyChild;
											setChildInEdition(emptyChild);
											setIsOpen(true);
										}}
									>
										<Typography variant="tableRow">
											{GetPrompts("Add")}
										</Typography>
									</span>
								</td>
							</tr>
						</table>
					</div>
				</>
			</PerfectScrollbar>

			<ChildCard_Edit
				open={isOpen}
				child={childInEdition}
				onClose={() => setIsOpen(false)}
				getChildren={FeedChildrenList}
				setUserId={0}
			/>

			<PlannedActivitiesPerChildPerYear
				isOpen={showActivitiesPerYear}
				close={() => {
					setShowActivitiesPerYear(false);
				}}
				child={childInEdition}
			/>
		</>
	);
}

export function ChildrenEmbededSelector({ selectedItem, setSelectedItem }) {
	var loadCount = 0;
	const [isOpen, setIsOpen] = useState(false);
	const [childrenList, setChildrenList] = useState([]);
	const [childInEdition, setChildInEdition] = useState("");

	const FeedChildrenList = () => {
		GetChildren({ currentPage: 0, itemsPerPage: 100 }).then(function (
			response
		) {
			setChildrenList(response.result);
		});
	};

	useEffect(() => {
		if (loadCount > 0) {
			return;
		}
		InitialLoad();
	}, [loadCount]);

	const InitialLoad = () => {
		FeedChildrenList();
		loadCount = loadCount + 1;
	};

	return (
		<>
			<div className="children-embeded-selector-container">
				<div className="children-embeded-selector-title base-sub-title">
					{GetPrompts("My Students Text")}
				</div>
				<>
					<div>
						{childrenList.map((child) => {
							var selectedchildId =
								selectedItem == undefined ? 0 : selectedItem.planChildId;
							return (
								<div
									className={`children-home-card-container selection-enabled ${
										child.planChildId == selectedchildId ? "selected-child" : ""
									}`}
									key={`children-home-card-container-key${child.planChildId}`}
									onClick={() => {
										setSelectedItem(child);
									}}
								>
									<div className="children-home-card-name">
										<div className="children-home-card-name-avatar">
											<Avatar sx={{ bgcolor: green[200] }}>
												<span className="children-home-card-name-avatar-text">
													{child.firstName.charAt(0)}
												</span>
											</Avatar>
										</div>
										<div className="children-home-card-name-text children-embeded-selector-name-text">
											{child.firstName} {child.lastName}
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</>
			</div>
		</>
	);
}
