import customAxios from "../../../axios";

export const ApplyAutomatedTermPlanning = async ({
	childId,
	yearId,
	fromDate,
	numberOfWeeks,
	requestList,
}) => {
	try {
		const url = `Planner/api/v1/ApplyAutomatedTermPlanning`;

		const response = await customAxios.post(url, {
			PlanChildId: childId,
			YearId: yearId,
			FromDate: fromDate,
			NumberOfWeeks: numberOfWeeks,
			AutomatedActivityRequestList: requestList,
		});
		return response.data;
	} catch (error) {}
};
