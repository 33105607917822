import React from "react";
import PropTypes from "prop-types";
import {
	MODAL_STYLES_BACKGROUND,
	RESPONSIVE_MODAL_STYLES,
} from "../Modal_Styles/ResponsiveModalStyle";

const ModalComponent = ({
	isOpen,
	onClose,
	children,
	overlayStyle,
	modalStyle,
	showBackgroundImage = false,
}) => {
	if (!isOpen) return null;

	return (
		<>
			<div
				className="modal-overlay"
				style={overlayStyle}
				onClick={onClose}
			></div>
			{showBackgroundImage === true ? (
				<div
					className="modal-container-image"
					style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES_BACKGROUND)}
				></div>
			) : (
				<></>
			)}
			<div
				className="modal-content"
				style={RESPONSIVE_MODAL_STYLES(modalStyle)}
			>
				{children}
			</div>
		</>
	);
};

ModalComponent.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	children: PropTypes.node.isRequired,
	overlayStyle: PropTypes.object,
	modalStyle: PropTypes.object,
};

ModalComponent.defaultProps = {
	overlayStyle: {},
	modalStyle: {},
};

export default ModalComponent;
