import React, { useContext, useEffect, useRef, useState } from "react";
import * as IoIcons from "react-icons/io";
import { Context } from "../../Context/Contexts";
import { GetUserProfilePicture } from "./GetUserProfilePicture";
import "./ProfilePictureUploader.css";
import { SaveUserProfilePicture } from "./SaveUserProfilePicture";
import ErrorPopUp from "../Errors/ErrorPopUp";

export default function ProfilePictureUploader({
	uploadPicture,
	setUploadPicture,
	openUploadDlg,
	setOpenUploadDlg,
}) {
	const [errorPopUpIsOpen, setErrorPopUpIsOpen] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const MAX_FILE_SIZE = 1048576; // 1MB = 1 * 1024 * 1024 bytes
	const [selectedImage, setSelectedImage] = useState(null);
	const [base64String, setBase64String] = useState("");
	const inputRef = useRef(null);
	const { updatePictureProfile, setupdatePictureProfile } = useContext(Context);

	const handleImageChange = (event) => {
		const file = event.target.files[0];
		if (file.size > MAX_FILE_SIZE) {
			setErrorMessage("El tamaño de la imagen no debe ser superior a 1 MB");
			setErrorPopUpIsOpen(true);
			return;
		}
		setSelectedImage(file);
	};

	const handleClick = () => {
		inputRef.current.click();
	};

	const handleUpload = async () => {
		if (selectedImage) {
			try {
				const formData = new FormData();
				formData.append("file", selectedImage);

				SaveUserProfilePicture({ file: formData }).then((response) => {
					setBase64String(response);
					setupdatePictureProfile(true);
				});
			} catch (error) {
				console.error("An error occurred while uploading the image:", error);
			}
		}
	};

	useEffect(() => {
		if (uploadPicture == true) {
			setUploadPicture(false);
			handleUpload();
		}
	}, [uploadPicture]);

	useEffect(() => {
		if (openUploadDlg == true) {
			setOpenUploadDlg(false);
			handleClick();
		}
	}, [openUploadDlg]);
	return (
		<div className="profile-picture-uploader-container">
			<div onClick={handleClick} className="profile-picture-uploader-img">
				{!selectedImage ? <ProfilePicture /> : ""}
				{selectedImage && (
					<>
						<img
							className="profile-picture-uploader-image-preview"
							src={URL.createObjectURL(selectedImage)}
							height={"120px"}
						/>
					</>
				)}
			</div>

			<div className="profile-picture-uploader-input-container">
				<input
					ref={inputRef}
					className="profile-picture-uploader-input"
					type="file"
					onChange={handleImageChange}
				/>
			</div>

			<ErrorPopUp
				open={errorPopUpIsOpen}
				onClose={() => setErrorPopUpIsOpen(false)}
				errorMessage={errorMessage}
			/>
		</div>
	);
}

export function ProfilePicture() {
	var loadCount = 0;
	const [base64String, setBase64String] = useState("");
	const { userIsMember } = useContext(Context);

	const GetProfilePicture = () => {
		GetUserProfilePicture().then((response) => {
			setBase64String(response);
		});
	};

	useEffect(() => {
		if (loadCount > 0) {
			return;
		}
		InitialLoad();
	}, [loadCount]);

	const InitialLoad = () => {
		GetProfilePicture();
		loadCount = loadCount + 1;
	};

	return (
		<div className="profile-picture-uploader-container">
			{base64String && (
				<div>
					<>
						<img
							src={`data:image/png;base64,${base64String}`}
							className="profile-picture-uploader-image-profile"
							alt="logo"
							height={"120px"}
						/>
						{userIsMember ? (
							<span className="profile-picture-overlay-text">MEMBER</span>
						) : (
							""
						)}
					</>
				</div>
			)}
			{!base64String ? (
				<div className="profile-picture-image-camera-container">
					<IoIcons.IoMdCamera className="profile-picture-image-camera" />
				</div>
			) : (
				""
			)}
		</div>
	);
}
