import axios from "../../../../axios";

export async function GetYearPlanDropdowns() {
  try {
    const url = `PlannerYearActivities/api/v1/GetYearPlanActivitiesDropdowns`
    const response = await axios.get(url);

    return response.data.result;
  } catch (error) {}
}
