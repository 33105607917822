import { useContext } from "react";
import { FaBars } from "react-icons/fa";
import { Context } from "../../Context/Contexts";

export default function SidebarToggle() {
  const { isSidebarOpen, setIsSidebarOpen } = useContext(Context);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      <div className="nav-item sidebar-toggle-btn" onClick={toggleSidebar}>
        <FaBars />
      </div>
    </>
  );
}
