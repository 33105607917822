import React from "react";
import logo from "../../Assets/OrlandoArnedo.jpg";
import { GetPrompts } from "../Prompts/PromptsComponent";
import { GotoDeveloperLink } from "./GotoDeveloperLink";
import "./OrlandoLink.css";
export default function OrlandoLink() {
	return (
		<>
			<div
				className="blog-link-icon"
				onClick={() => {
					GotoDeveloperLink();
				}}
			>
				<a>
					<img src={logo} className="developer-picture" alt="logo" width="30" />
				</a>{" "}
				<span className="developer-text sidebar-item-text overall-sidebar-menu-item">
					{" "}
					{GetPrompts("Created and maintained by Orlando Arnedo")}
				</span>
			</div>
		</>
	);
}
