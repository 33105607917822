import axios from "../../axios";
export const DeleteChild = async (userId, planChildId) => {
  try {
    const url = `Child/api/v1/DeleteChild`

    const response = await axios.post(url, {
      UserId: userId,
      PlanChildId: planChildId,
    });
  } catch (error) {}
};
