export const MEMBERSHIP_UPGRADE_NEEDED = "Membership Upgrade Needed";
export const MEMBERSHIP_ALREADY_PRESENT =
	"Already has a membership in the cart";
export const MEMBERSHIP_INTENT_TO_PURCHASE_ACTIVE_MEMBERSHIP =
	"Trying to purchase active membership";

export const MEMBERSHIP_INTENT_TO_PURCHASE_INVALID_COUPON_CODE =
	"Invalid coupon code";

export const MEMBERSHIP_INTENT_TO_PURCHASE_INVALID_COUPON_ALREADY_APPLIED =
	"Coupon already applied to user";

export const MEMBERSHIP_INTENT_TO_PURCHASE_INVALID_COUPON_FOR_PRODUCT =
	"Coupon invalid for this product";
