import React from "react";
import * as IoIcons from "react-icons/io";
import { RESPONSIVE_MODAL_STYLES } from "../Modal_Styles/ResponsiveModalStyle";
import "./ErrorPopUp.css";
import { MODAL_STYLES, OVERLAY_STYLES } from "./ErrorPopUp_ModalStyle";
export default function ({ open, onClose, errorMessage }) {
  if (!open) return null;
  return (
		<article className="errorpopup-container">
			<div style={OVERLAY_STYLES} onClick={onClose}></div>
			<div style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES)}>
				<div className="errorpopup-row-content">
					<button className="errorpopup-close-btn" onClick={onClose}>
						X
					</button>
				</div>
				<div>
					<IoIcons.IoMdInformationCircle className="errorpopup-error-icon" />
				</div>
				<div className="errorpopup-error-message base-sub-title">
					{errorMessage}
				</div>
			</div>
		</article>
	);
}
