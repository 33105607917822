import React, { useContext, useEffect, useState } from "react";
import * as IoIcons from "react-icons/io";
import CountyList from "../../Components/Countries/CountyList";
import InfoPopup from "../../Components/InfoPopup/InfoPopup";
import "../../Components/Modal_Styles/ResponsiveModalStyle.css";
import ProfilePictureUploader from "../../Components/ProfilePictureUploader/ProfilePicture";
import { GetPrompts } from "../../Components/Prompts/PromptsComponent";
import { Context } from "../../Context/Contexts";
import { EditUserProfile } from "./EditUserProfile";
import { GetUserByToken } from "./GetUserByToken";
import "./UserProfile_Modal.css";
import { MODAL_STYLES, OVERLAY_STYLES } from "./UserProfile_Modal_ModalStyle";
import { IsValidEmail } from "../../Components/IsValidEmail/IsValidEmail";
import ChildrenAdmin from "../../Components/Children/ChildrenAdmin/ChildrenAdmin";
import YesNoConfirm from "../../Components/ModalConfirm/YesNoConfirm";
import { DeleteMyAccount } from "./DeleteMyAccount";
import Impersonator from "../../Components/Impersonator/Impersonator";
import ModalComponent from "../../Components/ModalComponent/ModalComponent";

export default function UserProfile_Alt() {
	const lang = sessionStorage.getItem("_lang");
	const { setUpdateNavbarUserData } = useContext(Context);
	const { showIsloading, setShowIsloading } = useContext(Context);
	const { setShowDonecomponent } = useContext(Context);
	const { setExitApp } = useContext(Context);
	const [uploadPicture, setUploadPicture] = useState(false);
	const [dataLoaded, setDataLoaded] = useState(false);
	const [promptMessageIsOpen, setPromptMessageIsOpen] = useState("");
	const [promptMessage, setPromptMessage] = useState("");
	const [countryIsOpen, setCountryIsOpen] = useState(false);
	const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
	const { userProfileIsOpen, setUserProfileIsOpen } = useContext(Context);
	const [showImpersonator, setShowImpersonator] = useState(false);

	const [profileInEdition, setProfileInEdition] = useState(false);
	const [name, setName] = useState("");
	const [lastName, setLastName] = useState("");
	const [preferredName, setPreferredName] = useState("");
	const [email, setEmail] = useState("");
	const [language, seLanguage] = useState();
	const [countryId, setCountryId] = useState(0);
	const [editedCountryName, setEditedCountryName] = useState("");
	const [countryToDisplay, setCountryToDisplay] = useState("");

	const [user, setUser] = useState("");
	const [openUploadDlg, setOpenUploadDlg] = useState(false);
	const [showChildrenAdmin, setShowChildrenAdmin] = useState(false);

	const GetUser = async () => {
		setDataLoaded(false);
		setShowIsloading(true);
		await GetUserByToken().then((response) => {
			setUser(response);
			setDataLoaded(true);
			setShowIsloading(false);
		});
	};

	const triggerRefreshFunction = () => {
		window.location.reload();
	};

	const Close = () => {
		setProfileInEdition(false);
		setUserProfileIsOpen(false);
	};
	const EditUser = () => {
		if (email !== "" && !IsValidEmail(email)) {
			setPromptMessage(GetPrompts("We need a valid email"));
			setPromptMessageIsOpen(true);
			return;
		}

		setUploadPicture(true);
		EditUserProfile(
			email,
			name,
			lastName,
			preferredName,
			language,
			countryId
		).then((response) => {
			if (response.errors != null) {
				setPromptMessage(response.errors);
				setPromptMessageIsOpen(true);
				return;
			}
			GetUser();
			setProfileInEdition(false);
			setShowDonecomponent(true);
			setUpdateNavbarUserData(true);

			if (language != "" && language != undefined) {
				const delay = 3000; // 3 seconds
				const timerId = setTimeout(triggerRefreshFunction, delay);
				return () => clearTimeout(timerId);
			}
		});
	};
	const CloseClickHandler = () => {
		setProfileInEdition(false);
		setUserProfileIsOpen(false);
	};

	const DeleteButtonClickHandler = async () => {
		setShowIsloading(true);
		await DeleteMyAccount();
		setShowIsloading(false);
		setExitApp(true);
		CloseClickHandler();
	};

	useEffect(() => {
		if (userProfileIsOpen) {
			GetUser();
		}
	}, [userProfileIsOpen]);

	if (!userProfileIsOpen) return null;
	if (user == "") return null;
	return (
		<>
			<ModalComponent
				isOpen={userProfileIsOpen}
				onClose={CloseClickHandler}
				overlayStyle={OVERLAY_STYLES}
				modalStyle={MODAL_STYLES}
				showBackgroundImage={true}
			>
				<div className="user-profile-modal-container">
					<div
						className="user-profile-modal-close-btn"
						onClick={CloseClickHandler}
					>
						X
					</div>

					<div className="user-profile-modal-title base-title-normal-height">
						{GetPrompts("Your Profile")}
					</div>

					{user.canImpersonate === true ? (
						<div className="user-profile-modal-impersonator-btn-container">
							<div
								className="user-profile-modal-impersonator-btn"
								onClick={() => {
									setShowImpersonator(true);
								}}
							>
								IMPERSONATE
							</div>
						</div>
					) : (
						""
					)}

					<div className="user-profile-modal-picture-container">
						<ProfilePictureUploader
							uploadPicture={uploadPicture}
							setUploadPicture={setUploadPicture}
							openUploadDlg={openUploadDlg}
							setOpenUploadDlg={setOpenUploadDlg}
						/>

						<div className="user-profile-modal-picrure-text base-sub-title">
							<span
								className="user-profile-modal-picrure-inner-text"
								onClick={() => {
									setOpenUploadDlg(true);
								}}
							>
								{GetPrompts("Add photo")}
							</span>
						</div>
					</div>
					<div className="user-profile-modal-form-container">
						<div className="user-profile-modal-form-row">
							<div className="user-profile-modal-form-label table-row-header">
								{GetPrompts("Name text")}
							</div>

							<input
								id="user-profile-modal-first-name"
								type="text"
								className="user-profile-modal-form-input table-row-item"
								placeholder={user.firstName}
								defaultValue={user.firstName}
								onChange={(e) => setName(e.target.value)}
							/>
						</div>
						<div className="user-profile-modal-form-row">
							<div className="user-profile-modal-form-label table-row-header">
								{GetPrompts("Last Name text")}
							</div>
							<input
								id="user-profile-modal-last-name"
								type="text"
								className="user-profile-modal-form-input table-row-item"
								placeholder={user.lastName}
								defaultValue={user.lastName}
								onChange={(e) => setLastName(e.target.value)}
							/>
						</div>
						<div className="user-profile-modal-form-row">
							<div className="user-profile-modal-form-label table-row-header">
								{GetPrompts("NickName text")}
							</div>
							<input
								id="user-profile-modal-prefered-name"
								type="text"
								className="user-profile-modal-form-input table-row-item"
								placeholder={user.preferredName}
								defaultValue={user.preferredName}
								onChange={(e) => setPreferredName(e.target.value)}
							/>
						</div>
						<div className="user-profile-modal-form-row">
							<div className="user-profile-modal-form-label table-row-header">
								{GetPrompts("Email text")}
							</div>
							<input
								id="user-profile-modal-username"
								type="text"
								className="user-profile-modal-form-input table-row-item"
								placeholder={user.username}
								defaultValue={user.username}
								onChange={(e) => setEmail(e.target.value)}
							/>
						</div>

						<div className="user-profile-modal-form-row">
							<span className="user-profile-modal-form-label table-row-header">
								{GetPrompts("Students")}
							</span>
							<span
								id="user-profile-modal-form-input-btn"
								className="user-profile-modal-form-input-btn-container table-row-item"
								onClick={() => {
									setShowChildrenAdmin(true);
								}}
							>
								<div className="user-profile-modal-form-input-btn hover-brighten">
									<IoIcons.IoMdPeople /> <IoIcons.IoMdPeople />
								</div>
							</span>
						</div>

						<div className="user-profile-modal-form-row">
							<div className="user-profile-modal-form-label table-row-header">
								{GetPrompts("Country")}
							</div>
							<div
								className="user-profile-modal-form-input table-row-item"
								onClick={() => {
									setCountryIsOpen(true);
								}}
							>
								<div className="user-profile-modal-form-input-country">
									{countryToDisplay != ""
										? countryToDisplay
										: user.country.description}
								</div>
							</div>
						</div>
						<div className="user-profile-modal-form-row">
							<div className="user-profile-modal-form-label table-row-header">
								{GetPrompts("Language Text")}
							</div>
							<input
								id="user-profile-modal-language"
								type="text"
								className="user-profile-modal-form-input table-row-item"
								placeholder={user.language}
								defaultValue={user.language}
								onChange={(e) => seLanguage(e.target.value)}
							/>
						</div>
						<div className="user-profile-modal-form-row">
							<div className="user-profile-modal-form-label table-row-header">
								{/* Nothing */}
							</div>
							<div className="user-profile-modal-form-delete-btn-container table-row-item">
								<div
									className="user-profile-modal-form-delete-btn base-button-text hover-brighten"
									onClick={() => setDeleteConfirmationOpen(true)}
								>
									{GetPrompts("DeleteAccount")}
								</div>
							</div>
						</div>
					</div>

					<div
						className="user-profile-modal-back-btn hover-darken"
						onClick={() => {
							CloseClickHandler();
						}}
					>
						{GetPrompts("Back")}
					</div>
					<div
						id="user-profile-modal-save-btn"
						className="user-profile-modal-save-btn hover-brighten"
						onClick={() => {
							EditUser();
						}}
					>
						{GetPrompts("Save text")}
					</div>
				</div>
			</ModalComponent>

			<CountyList
				isOpen={countryIsOpen}
				close={() => {
					setCountryIsOpen(false);
				}}
				setCountryId={setCountryId}
				setCountryToDisplay={setCountryToDisplay}
			/>
			<InfoPopup
				icon={<IoIcons.IoMdCheckbox />}
				open={promptMessageIsOpen}
				onClose={() => setPromptMessageIsOpen(false)}
				errorMessage={promptMessage}
			/>

			<ChildrenAdmin
				open={showChildrenAdmin}
				close={() => setShowChildrenAdmin(false)}
			/>

			<YesNoConfirm
				dlgOpen={deleteConfirmationOpen}
				callBackFunction={DeleteButtonClickHandler}
				onClose={() => setDeleteConfirmationOpen(false)}
				confirmMessage={GetPrompts("Delete Account Confirmation Text")}
			/>
			<Impersonator
				isOpen={showImpersonator}
				closeHandler={() => {
					setShowImpersonator(false);
					setUserProfileIsOpen(false);
				}}
			/>
		</>
	);
}
