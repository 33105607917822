import axios from "../../axios";
export const CreateChild = async (
  firstName,
  lastName,
  identification,
  dateOfBirth,
  yearId
) => {
  try {
    const url = `Child/api/v1/CreateNewChild`

    const response = await axios.put(url, {
      UserId: 0,
      FirstName: firstName,
      LastName: lastName,
      Identification: identification,
      DateOfBirth: dateOfBirth,
      YearId: yearId,
    })
    return response.data;
  } catch (error) {}
}
